/* font sukar regular */
@font-face {
  font-family: "SukarRegular";
  src: url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/SukarRegular.eot?#iefix")
      format("embedded-opentype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/SukarRegular.woff")
      format("woff"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/SukarRegular.ttf")
      format("truetype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/SukarRegular.svg#SukarRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* font sukar black */
@font-face {
  font-family: "Sukar-black";
  src: url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-black.eot?#iefix")
      format("embedded-opentype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-black.woff")
      format("woff"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-black.ttf")
      format("truetype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-black.svg#Sukar-black")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* font sukar bold */
@font-face {
  font-family: "Sukar-Bold";
  src: url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-Bold.woff")
      format("woff"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-Bold.ttf")
      format("truetype"),
    url("https://clickmall-live.s3.me-south-1.amazonaws.com/cms-public/Fonts/Sukar-Bold.svg#Sukar-Bold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: "SukarRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir="rtl"] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir="rtl"] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir="rtl"] .has-success.has-feedback:after,
html[dir="rtl"] .has-warning.has-feedback:after,
html[dir="rtl"] .has-error.has-feedback:after,
html[dir="rtl"] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir="rtl"] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir="rtl"] .ant-modal-footer {
  text-align: left;
}

html[dir="rtl"] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir="rtl"] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir="rtl"] .ant-btn > .anticon + span,
html[dir="rtl"] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir="rtl"] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir="rtl"]
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir="rtl"] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir="rtl"] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir="rtl"] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir="rtl"] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir="rtl"]
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir="rtl"]
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir="rtl"] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir="rtl"] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir="rtl"] .dz-hidden-input {
  display: none;
}

.loader-class {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.loader-class .ant-spin {
  margin: 50vh 50vw;
}
.descriptionContainer {
  overflow: hidden;
}
.descriptionContainer img {
  height: auto;
  max-width: 100%;
}
.descriptionContainer ul {
  list-style: circle inside;
}
.image-note {
  margin-top: 10px;
  display: inline-block;
  font-size: 12px;
  color: gray;
}
.front-doc {
  border: 1px solid;
  display: block;
  height: 200px;
  width: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 18px auto;
}
.front-doc img {
  width: 100%;
  height: 100%;
}
.back-doc {
  max-width: 150px;
  display: inline-block;
  max-height: 200px;
  border: 1px solid;
  margin-left: 30px;
}
.back-doc img {
  width: 100%;
  height: 100%;
}
.search-btn {
  border-radius: 5px !important;
  margin-left: 15px;
}
.radius-18 {
  border-radius: 18px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.field-error {
  color: red;
}

/* marker */

.mapMarkerStyle {
  background-color: red;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  left: -0.5rem;
  top: -0.5rem;
  position: relative;
  border-radius: 1.5rem 1.5rem 0;
  transform: rotate(45deg);
  border: 1px solid #ffffff;
}

.mapMarkerStyle::after {
  position: "absolute";
  content: "";
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  background-color: red;
}
/* marker end */

.autocomplete-dropdown-container {
  background-color: white;
  border: 1px solid #d9d9d9;
  border-top: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
  position: absolute;
  width: 100%;
  z-index: 999;
}
.autocomplete-dropdown-container .suggestion-item {
  padding: 3px 10px;
}
.autocomplete-dropdown-container .suggestion-item--active {
  background-color: #4670a2 !important;
  color: white;
  padding: 3px 10px;
}

.orderItemTable {
  border: 1px solid gray;
  border-collapse: collapse !important;
}
.orderItemTable th,
.orderItemTable td {
  border: 1px solid gray;
}

/* ant button code design */
.ant-btn {
  border-radius: 4px !important;
  transition: all 0.5s ease;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #f9bb0d !important;
  border-color: #f9bb0d !important;
}
.ant-btn-primary {
  background: #f9bb0d !important;
  border: 1px solid #f9bb0d !important;
  color: #000 !important;
  transition: all 0.5s ease;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background: #f9bb0d !important;
  border: 1px solid #f9bb0d !important;
  color: #000 !important;
  transform: translateY(-2px);
}
a {
  color: #797979 !important;
  transition: all 0.5s ease;
}
a:hover {
  color: #f9bb0d !important;
}
.ant-input:hover {
  border-color: #f9bb0d !important;
}
.ant-input:focus {
  border-color: #f9bb0d !important;
  box-shadow: 0 0 0 2px rgba(249, 187, 13, 0.21) !important;
}
.isoLogoWrapper a:hover {
  color: #788195 !important;
}
.ant-switch {
  background-color: #000 !important;
}
.ant-switch-checked {
  background-color: #f9bb0d !important;
}
.ant-select-selection:hover {
  border-color: #f9bb0d !important;
  box-shadow: 0 0 0 2px rgba(249, 187, 13, 0.21) !important;
}
.ant-btn-danger {
  border-radius: 4px !important;
  transition: all 0.5s ease;
}
.ant-btn-danger:hover,
.ant-btn-danger:focus,
.ant-btn-danger:active {
  color: #fff !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
  transform: translateY(-2px);
}
.ant-pagination-item-active,
.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #f9bb0d !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #000 !important;
}
.ant-tabs-nav div.ant-tabs-ink-bar {
  background-color: #f9bb0d !important;
}
.anticon {
  color: #000 !important;
}

.pl-12 {
  padding-left: 12px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
/* topbar
 */
.top-shop-head .banner-head-left {
  margin: 0;
  display: inherit;
}
.top-shop-head .banner-head-left img {
  height: 38px;
}
.top-shop-head .banner-head-left span {
  color: #000;
  font-size: 20px;
  font-weight: normal;
  font-family: "Sukar-Bold";
}
.top-shop-head .banner-head-left span.rest-name {
  font-family: "Sukar-Bold";
}
.top-shop-head .banner-head-left span.seperator {
  padding: 0 7px;
}
.font-white {
  color: white !important;
}

.ant-select-dropdown-menu-item-selected {
  color: #f9bb0d !important;
}
input[type="file"] {
  white-space: pre-line;
}
.ant-select-selection--multiple {
  max-height: 150px;
  overflow: auto;
}
.product_list {
  max-height: 150px;
  overflow: auto;
}
.product_list td {
  min-width: 200px;
  word-break: break-all;
  white-space: pre-line;
}
.ant-select-dropdown-menu-item.testSelect {
  color: #f9bb0d !important;
  font-weight: bold;
}
